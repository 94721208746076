import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwDrivingExpGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>THE BMW CORPORATE DRIVING EXPERIENCE AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>What kind of Group Events can we have at your locations?</h5>
                <p>Our driving events are perfect for VIP meetings, corporate groups, bachelor and bachelorette parties, couples retreat, guys/girls weekend and much more. </p>
              </div>
              <div>
                <h5>Where is the BMW Performance Center located?</h5>
                <p>We currently have three locations in California, South Carolina and the Indianapolis Motor Speedway.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>1155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Indianapolis Motor Speedway</h5>
                <p>4790 W 16th St, Indianapolis, IN 46222<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the BMW Performance Center affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Are BMW Corporate Group Events offered at every location?</h5>
                <p>
                  Yes. We offer team building activities in Indianapolis, IN, Palm Springs, CA and Greenville, SC.                
                </p>
              </div>
              <div>
                <h5>How do you plan a BMW Corporate Team Building event?</h5>
                <p>
                  Please fill out the form below, and someone from our team will reach out to you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwDrivingExpGlance;